<template>
  <v-card class="mx-auto mt-5" max-width="800">
    <v-toolbar color="#3e6b96" dark>
      <v-toolbar-title>Order Details</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-list
      subheader
      v-for="orderDetail in orderDetails"
      :key="orderDetail.itemId"
    >
      <v-list-item class="grey lighten-4">
        <v-list-item-content>
          <v-list-item-title>Name :</v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title> {{ orderDetail.itemId }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Quantity :</v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-title>{{ orderDetail.quantity }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="grey lighten-4">
        <v-list-item-content>
          <v-list-item-title>Status :</v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-title>{{ orderDetail.statusId }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Amount :</v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-title>{{ orderDetail.itemTotal }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="grey lighten-4">
        <v-list-item-content>
          <v-list-item-title>Information Message :</v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
         <v-list-item-title v-if="paymentApproved === 'N'">
            Your Payment will be approved within 8 hours
          </v-list-item-title>
          <v-list-item-title v-if="paymentApproved === 'Y'">
            Payment Approved
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <p class="pt-5 text-center title" v-if="orderDetails.length === 0">No Data Available!</p>
    <v-card-actions class="d-flex justify-space-between">
      <v-btn v-if="paymentApproved === 'Y'"
        class="px-6 mt-4 mb-2" 
        color="primary" 
        :loading="isLoading" 
        dark 
        elevation="2"
        @click="refreshPage"
      >
        Refresh Balance
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn 
        class="px-6 mt-4 mb-2" 
        color="error" 
        dark 
        elevation="2" 
        to="/billingAcc"
      >
        Back
      </v-btn>
    </v-card-actions>

    <loading :active="isLoading" :loader="loader" />
  </v-card>
</template>

<script>
import billingAccountAPI from "@/services/billingAccountAPI.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "orderCancel",
  components: {
    Loading,
  },
  data: () => ({
    orderDetails: [],
    orderId: "",
    loader: "bars",
    isLoading: false,
    paymentApproved: "" 
  }),
  mounted() {
    this.orderId = this.$route.params.id;
    this.getCancelOrder();
  },
  methods: {
    async getCancelOrder() {
      this.isLoading = true;
      let odercancelData = {
        orderId: this.orderId,
      };
      try {
        let response = await billingAccountAPI.getOrderDetails(odercancelData);
        // console.log("response", response);
        this.isLoading = false;
        this.orderDetails = response.orderDetails.orderItemList;
        this.paymentApproved = response.orderDetails.paymentApproved;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async refreshPage() {
      this.isLoading = true; // Show loading before reloading
      setTimeout(() => {
        window.location.reload(); // Reload the page after showing the loader
      }, 500); // Small delay to ensure loader is visible
    }
  },
};
</script>